// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Gravity Forms
\* ------------------------------------------------------------------------ */

.gform_footer {
    & {
        left: -999em;
        position: absolute;
        top: -999em;
    }
}
