// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: $primary;
        background: var(--primary);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.8) $content-padding);
    }
}

.header_logo {
    .logo_image {
        height: remify(56, 16);
        width: auto;
    }
}

.header_title {
    & {
        font-size: remify(20, 16);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: $primary;
        background: var(--primary);
        padding: remify(($content-padding * 0.8) $content-padding);
    }

    > .header_inner {
        background: none;
        padding: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
