// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $accent;
        color: var(--accent);
        font-display: swap;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: remify(23, 16);
        font-weight: 900;
        line-height: remify(27, 23);
        margin: remify(0 0 15, 23);
        text-transform: uppercase;
    }
}

/* divider variant */

.title.-divider {
    & {
        align-items: center;
        display: flex;
    }

    &::after {
        background: $background_alt;
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(4);
        margin-left: remify(30, 23);
    }
}
