// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $secondary_alt;
        background: var(--secondary_alt);
        background: linear-gradient(to bottom, $secondary_alt 0%, $secondary 100%);
        background: linear-gradient(to bottom, var(--secondary_alt) 0%, var(--secondary) 100%);
        border: 0;
        border-radius: remify(12);
        box-shadow: remify(0 2 2 0) transparentize($dark, 0.88);
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-display: swap;
        font-family: $heading-font;
        font-size: remify(17, 16);
        font-weight: 700;
        line-height: remify(20, 17);
        margin: remify(0 0 20, 17);
        outline: 0;
        padding: remify(18 27 17, 17);
        text-decoration: none;
        text-transform: uppercase;
        transition: background 0.15s;
    }

    &:focus,
    &:hover {
        background: $secondary;
        background: var(--secondary);
        background: linear-gradient(to bottom, $secondary 0%, $secondary_alt 100%);
        background: linear-gradient(to bottom, var(--secondary) 0%, var(--secondary_alt) 100%);
    }
}

/* alt variant */

.button.-alt {
    & {
        background: $accent_alt;
        background: var(--accent_alt);
        background: linear-gradient(to bottom, $accent_alt 0%, $accent 100%);
        background: linear-gradient(to bottom, var(--accent_alt) 0%, var(--accent) 100%);
    }

    &:focus,
    &:hover {
        background: $accent;
        background: var(--accent);
        background: linear-gradient(to bottom, $accent 0%, $accent_alt 100%);
        background: linear-gradient(to bottom, var(--accent) 0%, var(--accent_alt) 100%);
    }
}

/* big variant */

.button.-big {
    & {
        font-range: $xxxs-break $xl-break;
        font-size: responsive remify(17, 16) remify(27, 16);
        padding: remify(15.11111111111111 28.96296296296296, 27);
    }
}

/* icon variant */

.button.-icon {
    & {
        padding-left: remify(51, 17);
        position: relative;
    }

    &::before {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: "";
        display: block;
        height: remify(19.2, 17);
        left: remify(15.7, 17);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: remify(19.2, 17);
        z-index: 1;
    }
}

/* icon download variant */

.button.-icon.-download {
    &::before {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.5,30L5.5,30C4.669,30 4,29.331 4,28.5L4,27C4,26.169 4.669,25.5 5.5,25.5L26.5,25.5C27.331,25.5 28,26.169 28,27L28,28.5C28,29.331 27.331,30 26.5,30ZM22.35,12.469L18.25,16.569L18.25,3.5C18.25,2.669 17.581,2 16.75,2L15.25,2C14.419,2 13.75,2.669 13.75,3.5L13.75,16.569L9.65,12.469C9.063,11.881 8.113,11.881 7.531,12.469L6.469,13.531C5.881,14.119 5.881,15.069 6.469,15.65L14.938,24.119C15.525,24.706 16.475,24.706 17.056,24.119L25.525,15.65C26.112,15.062 26.112,14.112 25.525,13.531L24.462,12.469C23.881,11.881 22.931,11.881 22.35,12.469Z' fill='%23FFFFFF' /%3E%3C/svg%3E%0A");
    }
}

/* icon heart variant */

.button.-icon.-heart {
    &::before {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.05,3.111C20.1,3.111 17.333,5.428 16,6.739C14.667,5.428 11.9,3.111 8.95,3.111C3.906,3.111 0.889,6.05 0.889,10.972C0.889,15.006 4.6,18.378 4.733,18.494L15.122,28.539C15.611,29.011 16.389,29.011 16.878,28.539L27.25,18.528C27.4,18.378 31.111,15 31.111,10.972C31.111,6.05 28.094,3.111 23.05,3.111Z' fill='%23FFFFFF' /%3E%3C/svg%3E%0A");
    }
}

/* icon big variant */

.button.-icon.-big {
    & {
        padding-left: remify(79.96296296296296, 17);
    }

    &::after {
        background: transparentize($dark, 0.88);
        border-radius: remify(12 0 0 12);
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        width: remify(51, 17);
        z-index: 0;
    }
}
