// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Grid (based loosely on https://v4-alpha.getbootstrap.com/layout/grid/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

.row {
    & {
        display: flex;
        flex-wrap: wrap;
    }

    & > [class*="col-"] {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
    }

    & > [class*="col-"].-nogrow {
        flex-grow: 0;
    }

    & > [class*="col-"].-noshrink {
        flex-shrink: 0;
    }

    &.-padded {
        width: calc(100% + #{remify($column-gap, 16)});
    }

    &.-padded > [class*="col-"] {
        padding-right: remify($column-gap, 16);
    }

    &.-padded.-tight {
        width: calc(100% + #{remify($column-gap * 0.75, 16)});
    }

    &.-padded.-tight > [class*="col-"] {
        padding-right: remify($column-gap * 0.75, 16);
    }

    &.-padded.-loose {
        width: calc(100% + #{remify($column-gap * 1.25, 16)});
    }

    &.-padded.-loose > [class*="col-"] {
        padding-right: remify($column-gap * 1.25, 16);
    }

    &.-nowrap {
        flex-wrap: nowrap;
    }

    &.-wrapreverse {
        flex-wrap: wrap-reverse;
    }

    &.-center {
        align-items: center;
        justify-content: center;
    }

    &:not(.-column).-hcenter {
        justify-content: center;
    }

    &:not(.-column).-vcenter {
        align-items: center;
    }

    &:not(.-column).-top {
        align-items: flex-start;
    }

    &:not(.-column).-right {
        justify-content: flex-end;
    }

    &:not(.-column).-bottom {
        align-items: flex-end;
    }

    &:not(.-column).-left {
        justify-content: flex-start;
    }

    &.-stretch {
        align-items: stretch;
    }

    &.-around {
        justify-content: space-around;
    }

    &.-between {
        justify-content: space-between;
    }

    &:not(.-column).-reverse {
        flex-direction: row-reverse;
    }

    &.-column {
        flex-direction: column;
    }

    &.-column.-vcenter {
        justify-content: center;
    }

    &.-column.-top {
        justify-content: flex-start;
    }

    &.-column.-right {
        align-items: flex-end;
    }

    &.-column.-bottom {
        justify-content: flex-end;
    }

    &.-column.-left {
        align-items: flex-start;
    }

    &.-column.-hcenter {
        align-items: center;
    }

    &.-column.-reverse {
        flex-direction: column-reverse;
    }
}

.col-auto {
    & {
        flex-basis: auto;
    }
}

@for $i from 0 through 12 {
    .col-#{$i} {
        flex-basis: ($i / 12) * 100%;
    }
}

@media screen and (min-width: $xs-break) {
    .col-xs-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-xs-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}

@media screen and (min-width: $s-break) {
    .col-s-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-s-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}

@media screen and (min-width: $m-break) {
    .col-m-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-m-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}

@media screen and (min-width: $l-break) {
    .col-l-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-l-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}

@media screen and (min-width: $xl-break) {
    .col-xl-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-xl-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}
