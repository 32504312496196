// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

/* ============================ GLOBAL VARIANTS =========================== */

.menu-list_container {
    & {
        clear: fix;
    }
}

.menu-list {
    & {
        clear: fix;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }
}

.menu-list_link {
    & {
        display: block;
        flex: 1 1 1%;
        min-width: 0;
        text-decoration: none;
    }
}

/* vertical variant */

.menu-list.-vertical {
    & {
        text-align: left;
    }

    > .menu-list_item {
        float: none;
    }
}

/* tier variant */

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }
}

/* tier overlay variant */

.menu-list[class*="-tier"].-overlay {
    & {
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacitwy 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: remify(250, 16);
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }
}

/* tier reverse variant */

.menu-list[class*="-tier"].-overlay.-reverse {
    & {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

/* tier1 overlay variant */

.menu-list.-tier1.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 0;
        top: 100%;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(-90deg);
    }
}

/* tier1 overlay reverse variant */

.menu-list.-tier1.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(90deg);
    }
}

/* tier2 overlay variant */

.menu-list.-tier2.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 100%;
        top: 0;
    }
}

/* tier2 overlay reverse variant */

.menu-list.-tier2.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 100%;
    }
}

/* ============================ CUSTOM VARIANTS =========================== */

.menu-list.-icons {
    & {
        font-family: $heading-font;
        font-size: remify(38, 16);
        text-transform: lowercase;
    }

    > .menu-list_item {
        padding: remify(16 0, 38);
    }

    > .menu-list_item:not(:last-child) {
        margin-right: remify(25, 38);
    }

    .menu-list_link {
        color: $light;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: darken($light, 39.2157);
    }

    .menu-list.-tier1 {
        background: lighten($dark_alt, 13.3333);
        font-size: remify(23, 38);
        padding: remify(12 0, 23);
        width: remify(220, 23);
    }

    .menu-list.-tier1::before {
        border-bottom: remify(12, 23) solid lighten($dark_alt, 13.3333);
        border-left: remify(12, 23) solid transparent;
        border-right: remify(12, 23) solid transparent;
        bottom: 100%;
        content: "";
        display: block;
        left: remify(24, 23);
        position: absolute;
    }

    .menu-list.-tier1.-reverse {
        transform: translateX(#{remify(16, 23)});
    }

    .menu-list.-tier1.-reverse::before {
        left: auto;
        right: remify(24, 23);
    }

    .menu-list.-tier1 .menu-list_link {
        background: lighten($dark_alt, 13.3333);
        padding: remify(5 20, 23);
        transition: background-color 0.15s;
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover {
        background-color: lighten(lighten($dark_alt, 13.3333), 5);
        color: $light;
    }

    .menu-list.-tier1 .menu-list_item.-featured > .menu-list_link {
        background: $accent;
        margin: remify(15 0, 23);
        padding-bottom: remify(16, 23);
        padding-top: remify(16, 23);
    }

    .menu-list.-tier1 .menu-list_item.-featured:last-child > .menu-list_link {
        margin-bottom: 0;
        padding-bottom: remify(20, 23);
    }

    .menu-list.-tier1 .menu-list_item.-featured > .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_item.-featured > .menu-list_link:hover {
        background-color: lighten($accent, 5);
        color: $light;
    }
}
