// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article_figure {
    & {
        margin: remify(0 0 30, 16);
    }
}

.article_image {
    & {
        display: block;
        width: 100%;
    }
}
