// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        text-align: center;
    }
}

.footer_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.8) $content-padding);
    }
}

.footer_link {
    & {
        color: $light;
    }

    &:focus,
    &:hover {
        color: $light;
        text-decoration: underline;
    }
}

.footer_text {
    & {
        color: darken($light, 28.2353);
        font-size: remify(23, 16);
        line-height: remify(23, 23);
    }

    ._small {
        line-height: 1.25em;
    }
}

.footer_divider {
    & {
        background-color: darken($light, 28.2353);
        margin-bottom: remify(20, 16);
    }
}

.footer_logo {
    & {
        margin: remify(0 auto 20, 16);
    }

    .logo_image {
        height: remify(55, 16);
        width: auto;
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        padding: remify(($content-padding * 0.8) $content-padding);
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}
