// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Link
\* ------------------------------------------------------------------------ */

.link {
    & {
        color: $accent;
        color: var(--accent);
        cursor: pointer;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        color: $accent_alt;
        color: var(--accent_alt);
    }
}

/* inherit variant */

.link.-inherit {
    & {
        color: inherit;
    }

    &:focus,
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}
