// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Catalog List
\* ------------------------------------------------------------------------ */

.catalog-list_divider {
    & {
        display: block;
        margin: remify(22.5 auto, 16);
        width: remify(54, 16);
    }
}

.catalog-list_container {
    & {
        background: $background;
        border: remify(1) solid darken($background, 40.7843);
        border-radius: remify(12);
        box-shadow: remify(0 2 2 0) transparentize($dark, 0.88);
        margin: remify(0 0 35, 16);
        min-height: remify(560, 16);
        overflow: hidden;
        padding: remify(8, 16);
    }
}

.catalog-list {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(13.5, 16);
        font-weight: 400;
        line-height: remify(16.495, 13.5);
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &.-child {
        font-size: remify(13.5, 13.5);
    }
}

.catalog-list_item {
    & {
        display: block;
    }

    .catalog-list.-child > & {
        background: $background;
        color: $foreground;
        cursor: pointer;
        display: block;
        padding: remify(4 8 4 22.5, 13.5);
        transition: background 0.15s, color 0.15s;
    }

    .catalog-list.-child > &.-title {
        color: $primary;
        font-family: $heading-font;
        font-weight: 900;
        padding-left: remify(8, 13.5);
        text-transform: uppercase;
    }

    .catalog-list.-child > &:focus,
    .catalog-list.-child > &:hover {
        background: $primary;
        color: $light;
    }
}

// jQuery multiselect

/* stylelint-disable selector-class-pattern */

.ms-selectable {
    & {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 109 138'%3E%3Cpath class='st0' d='M109,44.5L78.3,0L49.7,44.5h8.9v41.7h41.5V44.5H109z M0,93.5L30.7,138l28.6-44.5h-8.9V51.8H8.9 v41.7H0z' fill='%23D0DBE1' /%3E%3C/svg%3E%0A")  center bottom remify(22, 16) / remify(54 69, 16) no-repeat;
        padding-bottom: remify(90, 16);
    }
}

.ms-list {
    & {
        @extend .catalog-list_container;
    }

    .ms-selection & {
        @extend .catalog-list_container.-short;
    }
}

.ms-optgroup-container {
    & {
        display: block;
    }
}

.ms-optgroup {
    & {
        @extend .catalog-list;
        @extend .catalog-list.-child;
    }
}

.ms-optgroup-label,
.ms-elem-selectable,
.ms-elem-selection.ms-selected {
    & {
        @extend .catalog-list_item;
    }
}

.ms-optgroup-label {
    & {
        @extend .catalog-list_item.-title;
    }
}

/* stylelint-enable selector-class-pattern */
