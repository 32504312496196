// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background: $background_alt;
        border: 0;
        clear: both;
        height: remify(4);
        margin: remify(0 0 30, 16);
        width: 100%;
    }
}
