// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout {
    & {
        background: $background_alt;
        border-radius: remify(12);
        margin: remify(0 0 25);
        padding: remify(20);
    }
}

.callout_text {
    & {
        font-size: remify(16, 16);
        margin-bottom: remify(15, 16);
    }
}
